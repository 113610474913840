.form-box-register {
    width: 420px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;
}



.form-box-register h1 {
    font-size: 36px;;
    text-align: center;
    color: #fff;
}

.form-box-register .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 18px;
    border-radius: 40px;
    padding: 0px 45px 0px 10px;
}

.input-box input::placeholder {
    color: #fff;
}

.input-box .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
    font-size: 16px;
}

.form-box-register button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background: #fb8122;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border-radius: 40px;
    transition: 0.5s;
    font-weight: 700;
}

.form-box-register .Login-link {
    font-size: 14.5px;
    text-align: center;
    margin-top: 20px;
}

.Login-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.Login-link p a:hover {
    text-decoration: underline;
}