/* Header.css */

/* Full-width header with background image */
.intro {
  background-image: url('../../assets/IntroImage.png');
  /* Replace with correct image path */
  background-size: cover;
  /* Ensure the background image covers the whole area */
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
  /* Prevent background from repeating */
  height: 400px;
  /* Set fixed height for the Header */
  width: 100%;
  /* Full width of the viewport */
  display: flex;
  /* Flexbox to center the content */
  justify-content: center;
  /* Horizontally center content */
  align-items: center;
  /* Vertically center content */
  position: relative;
  /* Make sure it's positioned properly */
  z-index: 10;
  /* Ensure it's above other background elements */
  margin-bottom: 0px;
  /* Add space below the header to prevent overlap */
}

/* Content inside the header */
.intro-content {
  z-index: 20;
  /* Ensure the content is above the background image */
  text-align: center;
  /* Center the text */
  color: white;
  /* Text color */
}

.intro-title {
  font-size: 48px;
  /* Large font size for title */
  color: #ff9900;
  background-color: rgba(0, 0,0,0.5);
  /* Accent color for the title */
  margin: 0;
  /* Remove default margin */
}

.intro-subtitle {
  font-size: 24px;
  margin-top: 10px;
  color: white;
  /* White subtitle text */
}