/* Footer.css */
footer {
  background-color: #222725;
  padding: 20px;
  text-align: center;
  color: #222725;
  width: 100%;
  height: 70px;
  margin-top: 0; /* Remove any margin above */
}
