/* Stiluri pentru header */
.App-header {
  background-color: #000;
  /* Fundal negru */
  padding: 0;
  /* Elimină padding-ul inițial */
}

.nav-buttons {
  display: flex;
  justify-content: center;
  /* Centrează butoanele pe axa orizontală */
  align-items: center;
  /* Centrează butoanele pe axa verticală */
  height: 60px;
  /* Înălțimea barei de navigare */
  margin: 0;
}

.nav-button {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 20px;
  margin: 0px;
}

.Login {
  font-size: 18px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0 10px;
}

.button {
  padding: 15px 30px;
  /* Mărește padding-ul pentru butoane mai mari */
  background-color: #222222;
  color: white;
  text-decoration: none;
  border: no border;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 18px;
  /* Mărește fontul */
  margin: 0 10px;
  /* Spațiu între butoane */
}

.button:hover {
  background-color: #FB8122;
  /* Fundal albastru la hover */
  cursor: pointer;
}

.Login:hover {
  background-color: #3822fb;
  /* Fundal albastru la hover */
  cursor: pointer;
}

/* Container pentru cele trei elemente */
.container-row {
  display: flex;
  justify-content: space-around;
  /* Spațiu egal între containere */
  padding: 20px;
  margin-top: 20px;
}

.container {
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.container-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.container h3 {
  margin-top: 10px;
  color: #333;
  font-size: 24px;
}



.App-footer .button {
  background-color: #222222;
  /* Fără fundal */
  border: none;
  /* Fără border */
  font-size: 18px;
  /* Mărește fontul */
}

.App-footer .button:hover {
  background-color: #FB8122;
  /* Schimbă culoarea textului la hover */
  cursor: pointer;
  /* Schimbă cursorul la hover */
}


.align {
  text-align: center;
  /* Aliniere pe centru */
  color: #333;
  /* Opțional: Poți seta o culoare pentru text */
  margin-top: 20px;
  /* Opțional: Adaugă un spațiu deasupra titlului */
}

body {
  background-color: #3b3b3b;
  /* Fundal negru */
  color: #fff;
  /* Text alb */
}
.App-footer {
  background-color: #000;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
}