/* Info.css */
#info {
  margin: 0;
  padding: 20px; /* Adjust padding based on your needs */
  width: 100%;
  background-color: #6B7A8F;
}

#info .wrapper {
  margin: 0;
  padding: 20px;
  text-align: center;
  color: white;
}
