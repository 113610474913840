/* Game Container */
.game-container {
  width: 1350px;
  height: 700px;
  background-color: #222725;
  padding: 40px 20px;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

/* Question Heading */
h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #F7C331;
}

/* Option Buttons */
.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.option-button {
  background-color: #F7C331;
  color: white;
  border: none;
  padding: 15px 25px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-button:hover {
  background-color: #F7C331;
}

.option-button.selected {
  background-color: #F7C331;
}

/* Next Button */
.next-button {
  background-color: #F7C331;
  color: #222725;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button:hover {
  background-color: #F7C331;
}

.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Progress Bar */
.progress-bar {
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
}

.progress {
  height: 100%;
  background-color: #F7C331;
  width: 0;
  border-radius: 5px;
  transition: width 0.5s ease;
}

/* Result Section */
.result {
  padding: 20px;
  background-color: #F7C331;
  border-radius: 10px;
}

.result pre {
  text-align: left;
  font-size: 16px;
  background-color: #222725;
  padding: 20px;
  color: white;
  border-radius: 5px;
}

.restart-button {
  background-color: #F7C331;
  color: #222725;
  padding: 10px 30px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
