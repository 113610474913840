.caroussel {
  display: flex;
  height: 600px;
  width: 100%;
  overflow: hidden; /* Ensure the carousel doesn't overflow */
  position: relative;
}

.caroussel_wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Smooth transition for sliding */
  height: 100%;
}

.caroussel_card {
  display: flex;
  flex: 0 0 100%; /* Each card takes up 100% of the carousel width */
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  transform: scale(0.8);
  transition: 0.5s ease-in-out;
  color: #1995AD;
}

.card_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 40px 30px;
  align-items: flex-end;
  color: #1995AD;
}

.card_title {
  color: white;
  font-size: 30px;
}

.caroussel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.caroussel_arrow_left,
.caroussel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #F1F1F2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 7px;
  cursor: pointer;
}

.caroussel_arrow_left {
  left: 15px;
}

.caroussel_arrow_right {
  right: 15px;
}

.caroussel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination_dot {
  width: 10px;
  height: 10px;
  background-color: #F1F1F2;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  z-index: 1;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: steelblue;
}
.body {
  color: #1995AD;
}
.header, footer {
  color: initial;
}