.contact-form {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.contact-form input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 18px;
    border-radius: 40px;
    padding: 0px 45px 0px 10px;
}

.contact-form input::placeholder {
    color: #fff;
}

.wrapper button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background: #fb8122;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border-radius: 40px;
    transition: 0.5s;
    font-weight: 700;
}