.habit-tracker-container {
  text-align: center;
  padding: 20px;
  background-color: #6B7A8F;
  border-radius: 10px;
  width: 60%;
  margin: 0 auto;
  color: #ffffff;
}

.habit-question {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px; /* Add spacing between buttons */
}

button {
  background-color: #F7882F;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Reduce font size to fit text */
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #F7C331;
}

.result {
  margin-top: 20px;
}