#topics {
  background-color: #6B7A8F;
  padding: 20px;
  margin: 0;
}

#topics .topics-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

#topics .topics-list li {
  position: relative;
  font-family: "Alegreya", serif;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  padding-left: 32px; /* Adjust padding to accommodate the bullet */
}

#topics .topics-list li::before {
  content: "";
  position: absolute;
  background: #F7882F;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.bg-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.bg-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
