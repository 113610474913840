

/* General Page Layout */
.about-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    background-color: #6B7A8F;
    color: #fff;
    margin-bottom: 0; /* Ensure no extra space below */
  }
  
  .about-page h1 {
    font-size: 36px;
    color: #F7C331;
    margin-bottom: 20px;
  }
  
  .about-page h2 {
    font-size: 28px;
    color: #F7C331;
    margin: 20px 0;
  }
  
  .about-page p, .about-page pre {
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    max-width: 800px;
    margin-bottom: 30px;
  }
  
  .about-page pre {
    background-color: #F7882F;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
  }